export interface SerializedLocation {
  name: string;
  isComingSoon: boolean;
  address: string;
  path: string;
  position: {
    lat: number;
    lng: number;
  };
}

export interface SerializedLocationWithFormattedAddress
  extends SerializedLocation {
  formattedAddress: {
    line1: string;
    line2: string;
    line3: string;
  };
}

export const HARDCODED_LOCATIONS: SerializedLocation[] = [
  // New York City
  {
    name: 'Gramercy',
    isComingSoon: false,
    address: '380 2nd Avenue, New York, NY 10010',
    path: '/locations/new-york-city/gramercy',
    position: {
      lat: 40.737141961116784,
      lng: -73.98127597297012,
    },
  },
  {
    name: 'Upper East Side',
    isComingSoon: false,
    address: '1231 3rd Avenue, New York, NY 10021',
    path: '/locations/new-york-city/upper-east-side',
    position: {
      lat: 40.76934471347754,
      lng: -73.96068395947509,
    },
  },
  {
    name: 'Upper West Side',
    isComingSoon: false,
    address: '667 Columbus Ave, New York, NY 10025',
    path: '/locations/new-york-city/upper-west-side',
    position: {
      lat: 40.79080717246926,
      lng: -73.96882109995742,
    },
  },
  {
    name: 'West Village',
    isComingSoon: false,
    address: '15 7th Avenue, New York, NY 10011',
    path: '/locations/new-york-city/west-village',
    position: {
      lat: 40.737073249379726,
      lng: -74.00057147297015,
    },
  },
  {
    name: 'Williamsburg',
    isComingSoon: false,
    address: '118 N 4th St, Brooklyn, NY 11249',
    path: '/locations/new-york-city/williamsburg',
    position: {
      lat: 40.71699207969165,
      lng: -73.96073714413534,
    },
  },
  // Washington DC
  {
    name: 'Spring Valley',
    isComingSoon: false,
    address: '4301 49th Street, NW, Washington, DC 20016',
    path: '/locations/washington-dc/spring-valley',
    position: {
      lat: 38.94454,
      lng: -77.0963,
    },
  },
  {
    name: '14th St',
    isComingSoon: false,
    address: '2110 14th Street, NW, Washington, DC 20009',
    path: '/locations/washington-dc/14th-st',
    position: {
      lat: 38.91859,
      lng: -77.0322,
    },
  },
  {
    name: 'Bethesda',
    isComingSoon: false,
    address: '10253 Old Georgetown Rd, Bethesda, MD 20814',
    path: '/locations/washington-dc/bethesda',
    position: {
      lat: 39.02438,
      lng: -77.1238,
    },
  },
  {
    name: 'Navy Yard',
    isComingSoon: false,
    address: '949 First Street, SE, Washington DC, 20003',
    path: '/locations/washington-dc/navy-yard',
    position: {
      lat: 38.87885,
      lng: -77.0067,
    },
  },
  {
    name: 'McLean',
    isComingSoon: true,
    address: '6224C Old Dominion Drive, McLean, VA 22101',
    path: '/locations/washington-dc/mclean',
    position: {
      lat: 38.91998,
      lng: -77.1497,
    },
  },
  // Boston
  {
    name: 'Newton Centre',
    isComingSoon: false,
    address: '718 Beacon St, Newton, MA 02459',
    path: '/locations/boston/newton-centre',
    position: {
      lat: 42.33069784925326,
      lng: -71.19005990215237,
    },
  },
  {
    name: 'Brookline',
    isComingSoon: false,
    address: '297 Harvard St, Brookline, MA 02446',
    path: '/locations/boston/brookline',
    position: {
      lat: 42.343186573039176,
      lng: -71.1221525733167,
    },
  },
];

export const HARDCODED_LOCATIONS_WITH_FORMATTED_ADDRESS: SerializedLocationWithFormattedAddress[] = [
  // New York City
  {
    name: 'Gramercy',
    isComingSoon: false,
    address: '380 2nd Ave., New York, NY 10010',
    formattedAddress: {
      line1: '380 2nd Ave.',
      line2: 'New York',
      line3: 'NY 10010',
    },
    path: '/locations/new-york-city/gramercy',
    position: {
      lat: 40.737141961116784,
      lng: -73.98127597297012,
    },
  },
  {
    name: 'Upper East Side',
    isComingSoon: false,
    address: '1231 3rd Ave., New York, NY 10021',
    formattedAddress: {
      line1: '1231 3rd Ave.',
      line2: 'New York',
      line3: 'NY 10021',
    },
    path: '/locations/new-york-city/upper-east-side',
    position: {
      lat: 40.76934471347754,
      lng: -73.96068395947509,
    },
  },
  {
    name: 'Upper West Side',
    isComingSoon: false,
    address: '667 Columbus Ave., New York, NY 10025',
    formattedAddress: {
      line1: '667 Columbus Ave.',
      line2: 'New York',
      line3: 'NY 10025',
    },
    path: '/locations/new-york-city/upper-west-side',
    position: {
      lat: 40.79080717246926,
      lng: -73.96882109995742,
    },
  },
  {
    name: 'West Village',
    isComingSoon: false,
    address: '15 7th Ave., New York, NY 10011',
    formattedAddress: {
      line1: '15 7th Ave.',
      line2: 'New York',
      line3: 'NY 10011',
    },
    path: '/locations/new-york-city/west-village',
    position: {
      lat: 40.737073249379726,
      lng: -74.00057147297015,
    },
  },
  {
    name: 'Williamsburg',
    isComingSoon: false,
    address: '118 N. 4th St., Brooklyn, NY 11249',
    formattedAddress: {
      line1: '118 N. 4th St.',
      line2: 'Brooklyn',
      line3: 'NY 11249',
    },
    path: '/locations/new-york-city/williamsburg',
    position: {
      lat: 40.71699207969165,
      lng: -73.96073714413534,
    },
  },
  // Washington DC
  {
    name: 'Spring Valley',
    isComingSoon: false,
    address: '4301 49th St. NW, Washington, DC 20016',
    formattedAddress: {
      line1: '4301 49th St. NW',
      line2: 'Washington',
      line3: 'DC 20016',
    },
    path: '/locations/washington-dc/spring-valley',
    position: {
      lat: 38.94454,
      lng: -77.0963,
    },
  },
  {
    name: '14th St',
    isComingSoon: false,
    address: '2110 14th St. NW, Washington, DC 20009',
    formattedAddress: {
      line1: '2110 14th St. NW',
      line2: 'Washington',
      line3: 'DC 20009',
    },
    path: '/locations/washington-dc/14th-st',
    position: {
      lat: 38.91859,
      lng: -77.0322,
    },
  },
  {
    name: 'Bethesda',
    isComingSoon: false,
    address: '10253 Old Georgetown Rd., Bethesda, MD 20814',
    formattedAddress: {
      line1: '10253 Old Georgetown Rd.',
      line2: 'Bethesda',
      line3: 'MD 20814',
    },
    path: '/locations/washington-dc/bethesda',
    position: {
      lat: 39.02438,
      lng: -77.1238,
    },
  },
  {
    name: 'Navy Yard',
    isComingSoon: false,
    address: '949 First St. SE, Washington DC, 20003',
    formattedAddress: {
      line1: '949 First St. SE',
      line2: 'Washington',
      line3: 'DC 20003',
    },
    path: '/locations/washington-dc/navy-yard',
    position: {
      lat: 38.87885,
      lng: -77.0067,
    },
  },
  {
    name: 'McLean',
    isComingSoon: true,
    address: '6224C Old Dominion Dr., McLean, VA 22101',
    formattedAddress: {
      line1: '6224C Old Dominion Dr.',
      line2: 'McLean',
      line3: 'VA 22101',
    },
    path: '/locations/washington-dc/mclean',
    position: {
      lat: 38.91998,
      lng: -77.1497,
    },
  },
  // Boston
  {
    name: 'Newton Centre',
    isComingSoon: false,
    address: '718 Beacon St., Newton, MA 02459',
    formattedAddress: {
      line1: '718 Beacon St.',
      line2: 'Newton',
      line3: 'MA 02459',
    },
    path: '/locations/boston/newton-centre',
    position: {
      lat: 42.33069784925326,
      lng: -71.19005990215237,
    },
  },
  {
    name: 'Brookline',
    isComingSoon: false,
    address: '297 Harvard St., Brookline, MA 02446',
    formattedAddress: {
      line1: '297 Harvard St.',
      line2: 'Brookline',
      line3: 'MA 02446',
    },
    path: '/locations/boston/brookline',
    position: {
      lat: 42.343186573039176,
      lng: -71.1221525733167,
    },
  },
];
