export interface PressItemData {
  publication: string;
  url: string;
  previewTitle: string;
  image: string;
}

export const PRESS_ITEMS: PressItemData[] = [
  {
    publication: 'Forbes',
    url:
      'https://www.forbes.com/sites/forbes-personal-shopper/article/best-cat-collars/',
    previewTitle: 'The 6 Best Cat Collars That Are Safe And Stylish',
    image: '/press-logos/forbes.svg',
  },
  {
    publication: 'Business Insider',
    url:
      'https://www.businessinsider.com/guides/pets/best-cat-food-indoor-cats',
    previewTitle:
      'The best indoor cat foods in 2024, with advice from veterinarians',
    image: '/press-logos/businessinsider.svg',
  },
  {
    publication: 'CBS',
    url:
      'https://www.cbsnews.com/philadelphia/news/small-door-veterinary-has-a-new-solution-for-veterinarian-shortage/',
    previewTitle:
      'Small Door Veterinary has a new solution for veterinarian shortage',
    image: '/press-logos/cbs.svg',
  },
  {
    publication: 'New York Post',
    url:
      'https://nypost.com/2024/05/08/lifestyle/millennials-are-opting-for-vets-with-apps-cold-brew-and-art/',
    previewTitle:
      'Millennials now want vets who offer minimalist aesthetics, vibes and cold brew',
    image: '/press-logos/newyorkpost.svg',
  },
  {
    publication: 'Newsweek',
    url:
      'https://www.newsweek.com/rankings/americas-best-animal-hospitals-2025',
    previewTitle: "America's Best Animal Hospitals 2025",
    image: '/press-logos/newsweek.svg',
  },
];
