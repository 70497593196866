import React from 'react';
import { PRESS_ITEMS, PressItemData } from './pressItems';
import {
  FirstLogoRow,
  LogoGrid,
  LogoLink,
  LogoWrapper,
  SecondLogoRow,
  Section,
  StyledImage,
  Title,
} from './styles';

const Press: React.FC = () => {
  const firstRow = PRESS_ITEMS.slice(0, 3);
  const secondRow = PRESS_ITEMS.slice(3, 5);

  return (
    <Section>
      <Title>In the press</Title>
      <LogoGrid>
        <FirstLogoRow>
          {firstRow.map(item => (
            <PressItem key={item.publication} {...item} image={item.image} />
          ))}
        </FirstLogoRow>
        <SecondLogoRow>
          {secondRow.map(item => (
            <PressItem key={item.publication} {...item} image={item.image} />
          ))}
        </SecondLogoRow>
      </LogoGrid>
    </Section>
  );
};

const PressItem: React.FC<PressItemData> = ({
  publication,
  url,
  previewTitle,
  image,
}) => {
  return (
    <LogoLink
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      title={previewTitle}
    >
      <LogoWrapper>
        <StyledImage src={image} alt={publication} />
      </LogoWrapper>
    </LogoLink>
  );
};

export default Press;
