import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '../../../ui/variables';
import { Uppercase } from '../../../ui/TypographyStyles';

export const Section = styled.section`
  background-color: ${COLORS.NEW_LIGHT_BEIGE};
  padding: 80px 0;
  gap: 80px;
  margin: 0.5rem 0;

  @media ${BREAKPOINTS.TABLET} {
    padding: 60px 0;
  }
  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 12px;
  }
`;

export const LogoGrid = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const FirstLogoRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2vw;

  > * {
    max-width: 210px;
    width: 100%;
  }

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;

    > * {
      max-width: 210px;
      margin: 0 auto;
    }
  }

  @media ${BREAKPOINTS.MOBILE} {
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const SecondLogoRow = styled(FirstLogoRow)`
  padding: 0 22%;
  justify-content: space-between;
`;

export const Title = styled(Uppercase)`
  text-align: center;
  margin-bottom: 80px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 60px;
  }
`;

export const LogoLink = styled.a`
  display: block;
  width: 100%;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  aspect-ratio: 3/2;
  position: relative;

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 200px;
  }
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;
