import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { HomepageQuery } from '../../graphql-types';

import { BREAKPOINTS, COLORS } from '../ui/variables';
import Button from '../ui/BaseButton';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/homepage/Hero';
import Locations from '../components/homepage/Locations';
import Services from '../components/homepage/Services';
import Benefits from '../components/homepage/Benefits';
import Map from '../components/homepage/Map';
import Reviews from '../components/homepage/Reviews';
import Press from '../components/homepage/Press';

const Referral = styled.section`
  background-color: ${COLORS.GREEN};
  padding: 80px 20px;
  @media ${BREAKPOINTS.TABLET} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const ReferralText = styled.p`
  font-family: Adonis !important;
  font-size: 40px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};
  margin: 0 auto;
  margin-bottom: 40px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${BREAKPOINTS.MOBILESMALL} {
    max-width: 280px;
  }
`;

export const pageQuery = graphql`
  query Homepage {
    allContentfulPagesHomepage(filter: { pageId: { eq: "homepage" } }) {
      nodes {
        seoTitle
        seoDescription
        referralText
      }
    }
  }
`;

interface Props {
  data: HomepageQuery;
}

const IndexPage = ({ data }: Props) => {
  const copy = data.allContentfulPagesHomepage.nodes[0];

  return (
    <Layout promo='1'>
      <SEO title={copy.seoTitle} description={copy.seoDescription} />

      <Hero />

      <Locations />

      <Services />

      <Benefits />

      <Map />

      <Reviews />

      <Press />

      <Referral>
        <ReferralText>{copy.referralText}</ReferralText>
        <div
          css={css`
            margin: 0 auto;
            width: fit-content;
          `}
        >
          <Button type='internal' to='/referral' color={COLORS.ORANGE}>
            Refer a friend
          </Button>
        </div>
      </Referral>
    </Layout>
  );
};

export default IndexPage;
