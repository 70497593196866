import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Heading2 from '../../ui/Heading2';
import Button from '../../ui/BaseButton';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Subtitle, Uppercase } from '../../ui/TypographyStyles';

const Container = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 80px 40px;

  @media ${BREAKPOINTS.DESKTOPLARGE} {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const BenefitsList = styled.ul`
  padding: 80px 0;
  margin: 0;
  list-style: none;

  @media screen and (max-width: 820px) {
    padding: 60px 0;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 40px 0;
  }
`;

const Benefit = styled.li`
  margin-bottom: 80px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1242px;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${BREAKPOINTS.TABLET} {
    justify-content: space-between;
  }
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
  @media ${BREAKPOINTS.MOBILESMALL} {
    margin-bottom: 60px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 450px;
  height: fit-content;

  @media screen and (min-width: 1279px) {
    width: 594px;
  }
  @media ${BREAKPOINTS.DESKTOP} {
    margin-right: 80px;
  }
  @media screen and (max-width: 820px) {
    width: 100vw;
    height: fit-content;
  }
`;

const TextWrapper = styled.div`
  max-width: 430px;
  height: fit-content;

  @media ${BREAKPOINTS.DESKTOPLARGE} {
    max-width: 512px;
  }
  @media screen and (max-width: 980px) {
    margin-left: 50px;
  }
  @media ${BREAKPOINTS.TABLET} {
    max-width: 380px;
  }
  @media screen and (max-width: 820px) {
    max-width: 550px;
    margin-top: 40px;
    margin-left: 0;
    text-align: center;
  }
  @media ${BREAKPOINTS.MOBILESMALL} {
    margin-top: 24px;
  }
`;

const Heading = styled(Heading2)`
  line-height: 56px;
  width: fit-content;
  margin-bottom: 16px;
  @media screen and (max-width: 820px) {
    line-height: 40px;
    margin: 0 auto 12px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Text = styled(Subtitle)`
  margin-bottom: 0;
  @media ${BREAKPOINTS.MOBILE} {
    line-height: 26px;
  }
`;

const Benefits = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPagesHomepage(filter: { pageId: { eq: "homepage" } }) {
        nodes {
          benefits {
            benefits {
              title
              text
            }
          }
        }
      }
      uwsFront: file(relativePath: { eq: "pages/homepage/uws_front.webp" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 392, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      uwsDesktop: file(relativePath: { eq: "pages/homepage/uws_front.webp" }) {
        childImageSharp {
          fluid(maxWidth: 594, maxHeight: 389, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      baoBao: file(relativePath: { eq: "pages/homepage/Bao_Bao.webp" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      interior: file(relativePath: { eq: "pages/homepage/interior.webp" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      waffles: file(relativePath: { eq: "pages/homepage/Waffles.webp" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const copy = data.allContentfulPagesHomepage.nodes[0].benefits;

  return (
    <Container>
      <Uppercase
        css={css`
          margin: 0 auto;
          width: fit-content;
        `}
      >
        Why membership
      </Uppercase>
      <BenefitsList>
        <Benefit>
          <ImageWrapper>
            <Img
              fluid={data.uwsFront.childImageSharp.fluid}
              alt='Front view of Upper West Side practice.'
            />
          </ImageWrapper>
          <TextWrapper
            css={css`
              @media ${BREAKPOINTS.DESKTOPLARGE} {
                max-width: 555px;
              }
            `}
          >
            <Heading>{copy.benefits[0].title}</Heading>
            <Text>{copy.benefits[0].text}</Text>
          </TextWrapper>
        </Benefit>
        <Benefit>
          <ImageWrapper>
            <Img
              fluid={data.baoBao.childImageSharp.fluid}
              alt='Dr. Jamie examines Bao Bao the cat.'
            />
          </ImageWrapper>
          <TextWrapper>
            <Heading>{copy.benefits[1].title}</Heading>
            <Text>{copy.benefits[1].text}</Text>
          </TextWrapper>
        </Benefit>
        <Benefit>
          <ImageWrapper>
            <Img
              fluid={data.interior.childImageSharp.fluid}
              alt='Interior photograph of a practice.'
            />
          </ImageWrapper>
          <TextWrapper>
            <Heading>{copy.benefits[2].title}</Heading>
            <Text>{copy.benefits[2].text}</Text>
          </TextWrapper>
        </Benefit>
        <Benefit>
          <ImageWrapper>
            <Img
              fluid={data.waffles.childImageSharp.fluid}
              alt='Woman looking at her phone with her small dog on her lap.'
            />
          </ImageWrapper>
          <TextWrapper>
            <Heading>{copy.benefits[3].title}</Heading>
            <Text>{copy.benefits[3].text}</Text>
          </TextWrapper>
        </Benefit>
      </BenefitsList>
      <div
        css={css`
          margin: 0 auto;
          width: fit-content;
        `}
      >
        <Button
          type='external'
          href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
          color={COLORS.ORANGE}
        >
          Book Now
        </Button>
      </div>
    </Container>
  );
};

export default Benefits;
