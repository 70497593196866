import React, { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import {
  Container,
  Header,
  ReviewContainer,
  Review,
  StarWrapper,
  Star,
  ReviewHeader,
  Text,
  Name,
  CarouselContainer,
  CarouselViewport,
  DotContainer,
  Dot,
} from './styles';

interface Review {
  name: string;
  header: string;
  review: string;
}

const Reviews = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  const reviews: Review[] = [
    {
      name: 'Lais',
      header: 'The absolute BEST clinic for your animal.',
      review:
        'I literally drive 40min to make sure my pup is cared by them. From the warm decoration and design, to the excellent service and amazing vets. Your pup is in the best hands! Plus you can get everything you possibly need in their app, including 24/7 vet nurse advice. I have been with them for two years and I am never leaving!',
    },
    {
      name: 'Desirae C.',
      header: 'I am VERY particular when it comes to vet practices',
      review:
        'Small Door has never let me down. They have a beautiful practice designed to minimize stress and frustration. The staff is well versed in fear free practices and are SUPER patient/flexible with my anxious pup. They even encourage fun visits where you bring your dog in for treats and then leave without any medical procedures to build a positive association with the vet! Love it!! Thank you Small Door for taking extra great care of my best friend.',
    },
    {
      name: 'Jennifer I.',
      header: 'Best pet care in town!',
      review:
        'Always greeted by friendly staff, appointments start on time and the Vets take time to listen and also to clearly explain procedures. Their pricing is transparent and fair. I highly recommend Small Door.',
    },
  ];

  return (
    <Container>
      <Header>Reviews</Header>
      <ReviewContainer>
        {reviews.map((review, index) => (
          <Review key={index}>
            <StarWrapper>{new Array(5).fill(<Star />)}</StarWrapper>
            <ReviewHeader>{review.header}</ReviewHeader>
            <Text>{review.review}</Text>
            <Name>{review.name}</Name>
          </Review>
        ))}
      </ReviewContainer>

      <CarouselContainer ref={emblaRef}>
        <CarouselViewport>
          {reviews.map((review, index) => (
            <Review key={index}>
              <StarWrapper>{new Array(5).fill(<Star />)}</StarWrapper>
              <ReviewHeader>{review.header}</ReviewHeader>
              <Text>{review.review}</Text>
              <Name>{review.name}</Name>
            </Review>
          ))}
        </CarouselViewport>
      </CarouselContainer>

      <DotContainer>
        {reviews.map((_, index) => (
          <Dot
            key={index}
            isSelected={index === selectedIndex}
            onClick={() => emblaApi.scrollTo(index)}
          />
        ))}
      </DotContainer>
    </Container>
  );
};

export default Reviews;
