import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '../../../ui/variables';
import ParagraphMedium from '../../../ui/ParagraphMedium';
import { Uppercase } from '../../../ui/TypographyStyles';
import ReviewStar from '../../../images/icons/review-star-orange.svg';

export const Container = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 54px 50px;
  @media ${BREAKPOINTS.TABLET} {
    padding: 40px 0;
  }
`;

export const Header = styled(Uppercase)`
  text-align: center;
  margin-bottom: 20px;
`;

export const ReviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

export const CarouselContainer = styled.div`
  display: none;
  @media ${BREAKPOINTS.MOBILE} {
    display: block;
    overflow: hidden;
  }
`;

export const CarouselViewport = styled.div`
  display: none;
  @media ${BREAKPOINTS.MOBILE} {
    display: flex;
  }
`;

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 1rem;
  text-align: center;
  flex-basis: calc(33.33% - 20px);
  box-sizing: border-box;
  @media ${BREAKPOINTS.MOBILE} {
    flex: 0 0 100%;
  }
`;

export const StarWrapper = styled.span``;

export const Star = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(${ReviewStar}) no-repeat center;
`;

export const Text = styled(ParagraphMedium)`
  line-height: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ReviewHeader = styled.h2`
  font-size: 18px;
  margin: 15px 0;
  color: ${COLORS.BLACK};
  text-wrap: balance;
`;

export const Name = styled.span`
  display: block;
  color: ${COLORS.ORANGE};
  font-size: 14px;
  font-weight: 600;
`;

export const DotContainer = styled.div`
  display: none;
  @media ${BREAKPOINTS.MOBILE} {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const Dot = styled.button<{ isSelected: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: ${COLORS.ORANGE};
  margin: 0 4px;
  padding: 0;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.5')};
  cursor: pointer;
`;
