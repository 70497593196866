import React from 'react';
import { graphql } from 'gatsby';
import { NewtonCentreLocationPageQuery } from '../../../../graphql-types';

import LocationTemplate from '../../../components/locations/LocationTemplate';

export const pageQuery = graphql`
  query NewtonCentreLocationPage {
    allContentfulPagesLocationTemplate(
      filter: { pageId: { eq: "location_newton-centre" } }
    ) {
      nodes {
        locationName
        locationRegion
        seoTitle
        seoDescription
        isComingSoon
        aahaAccreditation
        displayOfferBanner
        offerBannerText {
          offerBannerText
        }
        couponCode
        addressLine1
        addressLine2
        phoneNumber
        emailAddress
        descriptionText1 {
          descriptionText1
        }
        descriptionText2 {
          descriptionText2
        }
        transportationTrain {
          transportationTrain
        }
        transportationBus {
          transportationBus
        }
        parking {
          parking
        }
        servicesList {
          services {
            name
            link
          }
        }
        concernsList {
          concerns {
            name
            link
          }
        }
        displayAppointmentsCalendar
        services
        locationTeam {
          name
          urlSlug
          avatar {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          shortenedBio {
            shortenedBio
          }
        }
        imagesVertical {
          description
          fixed(quality: 100, width: 475, height: 320) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imagesHorizontal {
          description
          fixed(quality: 100, width: 475, height: 320) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        schemaMarkup {
          _context
          _type
          name
          image
          _id
          url
          telephone
          address {
            _type
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
          geo {
            _type
            latitude
            longitude
          }
          openingHoursSpecification {
            _type
            dayOfWeek
            opens
            closes
          }
        }
        iframeUrl {
          iframeUrl
        }
        googleBusinessUrl
        breadcrumbSchema {
          _context
          _type
          itemListElement {
            _type
            position
            name
            item
          }
        }
        businessHours {
          openingHours {
            days
            hours
          }
        }
        memberReviews {
          reviewBody {
            reviewBody
          }
          name
        }
        googleBusinessReviews {
          googleBusinessReviews
        }
      }
    }
  }
`;
interface Props {
  data: NewtonCentreLocationPageQuery;
}

const NewtonCentreLocation = ({ data }: Props) => {
  const {
    locationName,
    locationRegion,
    seoTitle,
    seoDescription,
    isComingSoon,
    aahaAccreditation,
    displayAppointmentsCalendar,
    displayOfferBanner,
    offerBannerText,
    couponCode,
    addressLine1,
    addressLine2,
    phoneNumber,
    emailAddress,
    descriptionText1,
    descriptionText2,
    transportationTrain,
    transportationBus,
    parking,
    servicesList,
    concernsList,
    locationTeam,
    imagesVertical,
    imagesHorizontal,
    schemaMarkup,
    iframeUrl,
    googleBusinessUrl,
    breadcrumbSchema,
    memberReviews,
    businessHours,
    googleBusinessReviews,
  } = data.allContentfulPagesLocationTemplate.nodes[0];

  return (
    <LocationTemplate
      seo={{
        title: seoTitle,
        description: seoDescription,
        schema: schemaMarkup,
        breadcrumbSchema,
      }}
      offerBanner={{
        display: displayOfferBanner,
        text: offerBannerText.offerBannerText,
        couponCode,
      }}
      carouselDesktop={{
        images: [
          {
            fixed: imagesVertical[0].fixed,
            altText: imagesVertical[0].description,
          },
          {
            fixed: imagesHorizontal[0].fixed,
            altText: imagesHorizontal[0].description,
          },
          {
            fixed: imagesVertical[1].fixed,
            altText: imagesVertical[1].description,
          },
          {
            fixed: imagesHorizontal[1].fixed,
            altText: imagesHorizontal[1].description,
          },
          {
            fixed: imagesVertical[2].fixed,
            altText: imagesVertical[2].description,
          },
          {
            fixed: imagesHorizontal[2].fixed,
            altText: imagesHorizontal[2].description,
          },
          {
            fixed: imagesVertical[3].fixed,
            altText: imagesVertical[3].description,
          },
          {
            fixed: imagesHorizontal[3].fixed,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      carouselMobile={{
        images: [
          {
            fluid: imagesVertical[0].fluid,
            altText: imagesVertical[0].description,
          },
          {
            fluid: imagesHorizontal[0].fluid,
            altText: imagesHorizontal[0].description,
          },
          {
            fluid: imagesVertical[1].fluid,
            altText: imagesVertical[1].description,
          },
          {
            fluid: imagesHorizontal[1].fluid,
            altText: imagesHorizontal[1].description,
          },
          {
            fluid: imagesVertical[2].fluid,
            altText: imagesVertical[2].description,
          },
          {
            fluid: imagesHorizontal[2].fluid,
            altText: imagesHorizontal[2].description,
          },
          {
            fluid: imagesVertical[3].fluid,
            altText: imagesVertical[3].description,
          },
          {
            fluid: imagesHorizontal[3].fluid,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      location={{
        name: locationName,
        region: 'boston',
        isComingSoon,
        aahaAccreditation,
        address1: addressLine1,
        address2: addressLine2,
        phoneNumber,
        email: emailAddress,
        days1: businessHours.openingHours[0].days,
        days2: businessHours.openingHours[1].days,
        days3: businessHours.openingHours[2].days,
        hours1: businessHours.openingHours[0].hours,
        hours2: businessHours.openingHours[1].hours,
        hours3: businessHours.openingHours[2].hours,
        descriptionText1: descriptionText1.descriptionText1,
        descriptionText2: descriptionText2.descriptionText2,
        transportation1: transportationTrain.transportationTrain,
        transportation2: transportationBus.transportationBus,
        parking: parking.parking,
        services: servicesList.services,
        concerns: concernsList.concerns,
        team: locationTeam,
        iframeUrl: iframeUrl.iframeUrl,
        googleBusinessUrl,
        googleBusinessReviews: googleBusinessReviews.googleBusinessReviews,
        reviews: memberReviews,
      }}
      appointments={{
        locationId: 10,
        displayCalendar: displayAppointmentsCalendar,
      }}
    />
  );
};

export default NewtonCentreLocation;
