import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '../../../ui/variables';
import { NAV_HEIGHTS } from '../../header/types';

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 720px;
  overflow: hidden;
  margin: 0.5rem 0;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: 1fr;
    top: ${NAV_HEIGHTS.TABLET};
    height: auto;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media ${BREAKPOINTS.TABLET} {
    height: auto;
    min-height: 200px;
  }
`;

export const Heading1 = styled.h1`
  color: ${COLORS.BLACK};
  font-size: 44px;
  line-height: 64px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: Gibson;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 32px;
    line-height: 48px;
    margin: 0;
  }
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;

  @media ${BREAKPOINTS.TABLET} {
    min-height: 650px;
  }
`;

export const InfoWindowContentContainer = styled.div`
  height: 100%;
  width: 350px;
  background-color: ${COLORS.WHITE};
  padding: 0 0 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media ${BREAKPOINTS.TABLET} {
    width: 250px;
  }
`;

export const InfoWindowHeading = styled.h3`
  font-family: Gibson;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
`;

export const InfoWindowAddress = styled.address`
  font-family: Gibson;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  font-style: normal;
  text-transform: uppercase;
  color: ${COLORS.DARK_GRAY};
  margin: 0px;
`;
